<template>
  <v-card tile class="p-4 fontUrbanist">
    <v-card-title class="text-center">
      <v-row>
        <v-col cols="12">
          <h3 class="text-h5 font-weight-bold">{{ title }}</h3>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row class="d-flex flex-row justify-center">
        <v-col cols="12" md="9">
          <ValidationProvider :name="label" rules="" v-slot="{ errors }" slim>
            <v-text-field
              class="fontUrbanist"
              v-model="trip_comment"
              :label="label"
              outlined
              dense
              maxlength="60"
              :error-messages="errors[0]"
              @keydown.enter="handleAdd"
            >
            </v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-row justify-center">
        <v-col cols="12" md="9">
          <div class="d-flex flex-row justify-space-between">
            <v-btn
              class="fontUrbanist"
              color="primary"
              outlined
              max-width="400"
              @click="handleClose"
            >
              {{ cancelMsg }}
            </v-btn>

            <v-btn
              class="fontUrbanist"
              color="primary"
              max-width="400"
              @click="handleAdd"
            >
              {{ acceptMsg }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
import { mapActions } from "vuex";

export default {
  props: {
    item: {
      type: Object,
    },
    label: {
      type: String,
    },
    title: {
      type: String,
    },
    acceptMsg: {
      type: String,
    },
    cancelMsg: {
      type: String,
    },
  },
  data() {
    return {
      trip_comment: null,
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    handleClose() {
      this.$emit("submit", null);
    },
    async handleAdd() {
      this.setLoading(true);
      const apiUrl = `/add_trip_comment/${this.item._id}`;
      try {
        const { data } = await axios.post(apiUrl, {
          trip_comment: this.trip_comment,
        });
        if (!!data && data.success) {
          this.$dialog.notify.success("Comentario agregado con éxito");
          this.$emit("submit", true);
        } else {
          if (!!data && !data.error)
            throw new Error("Error al agregar comentario");
          this.$dialog.notify.error(data.error);
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      } finally {
        this.setLoading(false);
      }
    },
  },
};
</script>

<style scoped>
.text-h5 {
  font-family: "Urbanist", sans-serif !important;
}
</style>

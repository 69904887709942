var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-data-table',{staticClass:"dataTable elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.computedHeaders,"disable-sort":true,"items":_vm.items,"calculate-widths":"","hide-default-footer":"","disable-pagination":"","fixed-header":"","mobile-breakpoint":"0","height":_vm.computedHeight,"dense":true},scopedSlots:_vm._u([{key:"header.source_address",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{staticStyle:{"max-width":"20px"},attrs:{"src":require('@/views/RunningTrips/assets/IconOriginMap.png')}}),_vm._v(" "+_vm._s(header.text)+" ")],1)]}},{key:"header.destination_address",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{staticStyle:{"max-width":"20px"},attrs:{"src":require('@/views/RunningTrips/assets/IconDestinationMap.png')}}),_vm._v(" "+_vm._s(header.text)+" ")],1)]}},{key:"item.unique_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('td',[_c('a',{attrs:{"target":"_blank","href":("/view-map/" + (item._id))}},[_vm._v(" "+_vm._s(item.unique_id)+" ")]),(item.is_multi_stop)?_c('span',[_c('br'),_c('v-chip',{attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.handleMultiStopModal(item)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiMapMarkerPath)+" ")]),_vm._v(" "+_vm._s(_vm.$t("multi_stop"))+" ")],1)],1):_vm._e()])])]}},{key:"item.destination_address",fn:function(ref){
var item = ref.item;
return [(item.is_multi_stop)?_c('td',{staticClass:"d-flex flex-row align-baseline"},[_vm._v(" "+_vm._s(item.destination_address)+" "),_c('span',{staticClass:"cursorPointer",on:{"click":function($event){return _vm.handleMultiStopModal(item)}}},[_c('v-badge',{attrs:{"right":"","color":"warning","content":item.routes.length,"offset-y":"10"}})],1)]):_c('td',[_vm._v(_vm._s(item.destination_address))])]}},{key:"item.provider_name",fn:function(ref){
var item = ref.item;
return [(item.provider_name.length === 1)?_c('span',[_vm._v(_vm._s(_vm.$t("No driver")))]):_c('a',{attrs:{"target":"_blank","href":("/provider/" + (item.provider_id))}},[_vm._v(" "+_vm._s(item.provider_name)+" ")])]}},{key:"item.trip_comment",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-start"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"truncate-text d-block"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.trip_comment)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.trip_comment)+" ")])],1)]}},{key:"item.user_name",fn:function(ref){
var item = ref.item;
return [(item.is_corporate_user)?_c('a',{attrs:{"target":"_blank","href":("/user/" + (item.user_id))}},[_vm._v(" "+_vm._s(item.user_name)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.user_name)+" ")]),_c('br'),(item.user_phone)?_c('a',{attrs:{"href":("tel:" + (item.user_phone))}},[_vm._v(" "+_vm._s(item.user_phone.substring(3))+" ")]):_vm._e()]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [(item.trip_status === 'Completed')?_c('a',{attrs:{"target":"_blank","href":("/invoice/" + (item.unique_id))}},[(
            item.total && item.total !== 0 && item.total !== item.total_total
          )?_c('span',[_c('span',{staticClass:"text-decoration-line-through error--text"},[_vm._v(_vm._s(_vm._f("currency")(item.total)))]),_vm._v(" "+_vm._s(_vm._f("currency")(item.total_total))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.total_total))+" ")]),(
            item.total_corporate_waiting_time > 0 &&
            item.corporate_waiting_time_cost > 0
          )?_c('span',[_c('br'),_vm._v(" "+_vm._s(item.total_corporate_waiting_time)+" minutos("+_vm._s(_vm._f("currency")(item.corporate_waiting_time_cost))+") ")]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]),(item.is_special_trip)?_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"#ffa500"}},[_c('br'),_vm._v(" "+_vm._s(_vm.$t("special_trip"))+" ")]):_vm._e()]}},{key:"item.date",fn:function(ref){
          var item = ref.item;
return [_c('td',[(!item.server_start_time_for_schedule)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD-MM-YYYY h:mm a"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.server_start_time_for_schedule,"DD-MM-YYYY h:mm a"))+" ")])])]}},{key:"item.trip_status",fn:function(ref){
          var item = ref.item;
return [(item.trip_status === 'Cancelled')?_c('v-chip',{staticClass:"v-chip-light-bg error--text",attrs:{"color":"accent"}},[_vm._v(" "+_vm._s(_vm.$t(item.trip_status)))]):(item.trip_status === 'Completed')?_c('v-chip',{staticClass:"v-chip-light-bg success--text",attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t(item.trip_status)))]):_c('v-chip',[_vm._v(" "+_vm._s(_vm.$t(item.trip_status))+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"rounded":".rounded-lg","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var attrs = ref.attrs;
          var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[(item.trip_status === 'Completed')?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleInvoice(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("View invoice")))])],1):_vm._e(),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.handleShowUser(item)}}},[_vm._v(_vm._s(_vm.$t("View user")))])],1),(item.provider_id)?_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.handleShowProvider(item)}}},[_vm._v(_vm._s(_vm.$t("View provider")))])],1):_vm._e(),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleViewMap(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("View map")))])],1),(!item.trip_comment)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleAddDescription(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Add Description")))])],1):_vm._e()],1)],1)]}},{key:"item.serviceType",fn:function(ref){
          var value = ref.value;
return [(value)?_c('span',{staticClass:"d-flex flex-column justify-center align-center mt-2"},[_c('v-tooltip',{attrs:{"top":"","content-class":"bs-tooltip-top arrow","max-width":300,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-flex flex-column justify-center align-center"},'span',attrs,false),on),[_c('v-img',{attrs:{"src":("" + _vm.bucketUrl + (value.type_image_url)),"max-width":"60"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),_c('span',{staticClass:"fontUrbanist font-weight-bold"},[_vm._v(_vm._s(value.typename))])],1)]}}],null,true)},[_c('span',{staticClass:"d-flex flex-row justify-center align-center"},[_c('v-icon',{style:(!_vm.$vuetify.theme.isDark && 'color: #ffffff !important;'),attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccount)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(value.max_space)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" | ")]),_c('v-icon',{staticClass:"ml-2",style:(!_vm.$vuetify.theme.isDark && 'color: #ffffff !important;'),attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiBagSuitcase)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(value.baggages)+" ")])],1)])],1):_c('span')]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }